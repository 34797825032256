import ApiService from "./api.service";
let main = "getInfo";
export default {
    getList() {
        return ApiService.get(`${main}/organizationRequestListByPnfl`);
    },
    letterResponseNewList() {
        return ApiService.get(`letterResponse/newList`);
    },
    mailReceiptNewList() {
        return ApiService.get(`mailReceipt/newList`);
    },
    achievementByLawyerId(id) {
        return ApiService.post(`${main}/achievementByLawyerId?lawyerId=${id}`);
    },
    getSiteInfoList(data) {
        return ApiService.post(`getInfo/directorySiteInfoList`, data);
    },
    lawyerAchievementCreate(data) {
        return ApiService.post(`${main}/lawyerAchievementCreate`, data);
    },

    directoryMainTypeList(data) {
        return ApiService.post(`${main}/directoryMainTypeList`, data);
    },
    lawyerMainTypes(id) {
        return ApiService.post(`${main}/lawyerMainTypes?lawyerId=${id}`);
    },
    lawyerMainTypeCreate(lawyerId, ids) {
        return ApiService.post(
            `${main}/lawyerMainTypeCreate?lawyerId=${lawyerId}&mainTypes=${ids}`
        );
    },

    getById(id) {
        return ApiService.get(`${main}/organizationRequestById/${id}`);
    },
    closeOrganizationRequest(form) {
        let params = new FormData();
        params.append("closeFile", form.file);
        params.append("comment", form.comment);
        return ApiService.post(
            `${main}/closeOrganizationRequest/${form.orgRequestId}`,
            params
        );
    },
    acceptOrgRequest(orgRequestId) {
        return ApiService.post(
            `${main}/acceptOrgRequestNew?orgRequestId=${orgRequestId}`
        );
    },
    cancelOrgRequest({ comment, orgRequestId }) {
        return ApiService.post(
            `${main}/cancelOrgRequestNew?comment=${comment}&orgRequestId=${orgRequestId}`
        );
    },
    // http://localhost:8282/api/v.1/getInfo/notificationList
    notificationList() {
        return ApiService.post(`${main}/notificationList`, {
            page: 0,
            limit: 100,
        });
    },
    notificationById(id) {
        return ApiService.get(`${main}/notificationById/${id}`);
    },
};
